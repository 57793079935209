import PropTypes from "prop-types";
import React, { useMemo } from 'react';

import { Breadcrumbs, Typography, Link, Box } from '@material-ui/core';
import { SectionContainer } from "@product-site-frontend/shared";

function BreadcrumbsComponent({ breadcrumbs, sx }) {
  const styles = useMemo(() => ({
    breadcrumbsLine: {
      padding: '12px',
      borderTop: '1px solid #ebebeb',

      ...sx.breadcrumbsLine
    },
    sectionContainer: {
      paddingTop: '0',
      paddingBottom: '0',

      ...sx.sectionContainer
    },
    wrapperBreadcrumb: {
      ...sx.wrapperBreadcrumb
    },
    breadcrumb: {
      textDecoration: 'none',
      fontSize: '14px',
      lineHeight: '24px',
      color: '#9EA5AD',
      cursor: 'pointer',
      display: 'inline-flex',
      alignItems: 'center',
      height: '24px',

      '&:hover': {
        color: '#FF780F'
      },

      ...sx.breadcrumb
    },
    breadcrumbText: {
      fontSize: '14px',
      lineHeight: '24px',
      color: '#CFD2D6',
      display: 'inline-flex',
      alignItems: 'center',
      height: '24px',

      ...sx.breadcrumbText
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }), []);

  return (
    <Box sx={styles.breadcrumbsLine}>
      <SectionContainer sx={styles.sectionContainer}>
        <Breadcrumbs
          aria-label="breadcrumb"
          itemScope
          itemType="https://schema.org/BreadcrumbList"
          maxItems={4}
          separator="/"
          sx={styles.wrapperBreadcrumb}
        >
          {breadcrumbs.map((breadcrumb, index) => {
            if (breadcrumb?.href) {
              return (
                <div
                  itemProp="itemListElement"
                  itemScope
                  itemType="https://schema.org/ListItem"
                  style={{
                    display: 'inline-flex',
                    alignItems: 'center',
                    height: '24px'
                  }}
                  key={index}
                >
                  <Link
                    href={breadcrumb.href}
                    itemprop="item"
                    sx={styles.breadcrumb}
                    title={breadcrumb.text}
                  >
                    <span
                      itemProp="name"
                    >
                      {breadcrumb.text}
                    </span>
                    <meta content={index} itemProp="position" />
                  </Link>
                </div>
              )
            } else {
              return (
                <div
                  itemProp="itemListElement"
                  itemScope
                  itemType="https://schema.org/ListItem"
                  style={{
                    display: 'inline-flex',
                    alignItems: 'center',
                    height: '24px'
                  }}
                  key={index}
                >
                  <Typography
                    itemProp="item"
                    sx={styles.breadcrumbText}
                  >
                    <span
                      itemProp="name"
                    >
                      {breadcrumb.text}
                    </span>
                    <meta content={index} itemProp="position" />
                  </Typography>
                </div>
              );
            }
          })}
        </Breadcrumbs>
      </SectionContainer>
    </Box>
  );
}

BreadcrumbsComponent.propTypes = {
  breadcrumbs: PropTypes.array.isRequired, //examply:  [{href: 'test', text: 'text'}, {text: 'text'}]
  sx: PropTypes.object,
};

BreadcrumbsComponent.defaultProps = {
  sx: {
    breadcrumbsLine: {},
    wrapperBreadcrumb: {},
    breadcrumb: {},
    breadcrumbText: {}
  },
  breadcrumbs: [],
};

export default BreadcrumbsComponent;